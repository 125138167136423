import type { GridRenderCellParams } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import HoverableTooltip from '../../../../../../components/dataTable/components/HoverableTooltip';
import TableCell from '../../../../../../components/dataTable/components/TableCell';
import EditIcon from '../../../../../../components/icons/EditIcon';
import useQuestionPointsOptions from '../../../../../../hooks/api/questions/useQuestionPointsOptions';
import useFindTranslation from '../../../../../../hooks/useFindTranslation';
import { routes } from '../../../../../../static/routes';
import { colors } from '../../../../../../styles/variables';
import type { IQuestion } from '../../../../../../types/ApiTypes';
import QuestionListTableDeleteBtn from '../../components/QuestionListTableDeleteBtn';
import QuestionsListTableTags from '../../components/QuestionsListTableTags';
import { PointsCell } from './components/PointsCell';

const useQuestionsTableHeaders = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { findTranslation } = useFindTranslation();
  const { pointsOptions: allPointsOptions, pointsFallbackColor } = useQuestionPointsOptions();

  const headers = [
    {
      field: 'name',
      headerName: t('questions.table.headers.name'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      valueGetter: (params: GridRenderCellParams<IQuestion>) => findTranslation(params.row.name),
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{findTranslation(params.row.name)}</TableCell>;
      },
    },
    {
      field: 'owner',
      headerName: t('questions.table.headers.owner'),
      minWidth: 120,
      flex: 1,
      sortable: true,
      sortComparator: (v1: { email: string }, v2: { email: string }) => {
        const owner1 = v1?.email ?? '';
        const owner2 = v2?.email ?? '';
        return new Intl.Collator().compare(owner1, owner2);
      },
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{params.row.owner?.email}</TableCell>;
      },
    },
    {
      field: 'city',
      headerName: t('questions.table.headers.address'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{params.row.city ?? ''}</TableCell>;
      },
    },
    {
      field: 'questionType',
      headerName: t('questions.table.headers.questionType'),
      minWidth: 120,
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <TableCell>{t(`questionTypes.${params.row.questionType.code}`)}</TableCell>;
      },
    },
    {
      field: 'points',
      headerName: t('questions.table.headers.points'),
      minWidth: 64,
      maxWidth: 120,
      valueGetter: (params: GridRenderCellParams<IQuestion>) => params.row.points,
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        const points = params.row.points;
        const color =
          allPointsOptions?.find(option => option.points === points?.toString())?.color ?? pointsFallbackColor;

        const questionPointsOptions = params.row.pointsOptions;

        return <PointsCell points={points} pointsOptions={questionPointsOptions} color={color} />;
      },
    },

    {
      field: 'tags',
      headerName: t('questions.table.headers.tags'),
      minWidth: 120,
      maxWidth: 240,
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return <QuestionsListTableTags tags={params.row.tags} />;
      },
    },

    {
      field: 'buttons',
      headerName: '',
      minWidth: 100,
      maxWidth: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<IQuestion>) => {
        return (
          <div className="flex justify-end">
            <HoverableTooltip tooltipContent={<p>{t('questions.table.buttons.edit')}</p>}>
              {isHovered => (
                <button
                  className="p-3"
                  onClick={e => {
                    e.stopPropagation();

                    const currentPath = `${location.pathname}${location.search}`;

                    navigate(routes.editQuestion(params.row.id.toString()), {
                      state: { prevPath: currentPath },
                    });
                  }}
                >
                  <EditIcon color={isHovered ? colors.red500 : colors.grey700} width="24" height="24" />
                </button>
              )}
            </HoverableTooltip>

            <QuestionListTableDeleteBtn questionId={params.row.id.toString()} />
          </div>
        );
      },
    },
  ];

  return { headers };
};

export default useQuestionsTableHeaders;
