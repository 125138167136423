const CELL_BASE_CLASSES = 'w-9 h-9 flex justify-center items-center px-2.5 py-2';

type PointsCellWrapperProps = {
  children?: React.ReactNode;
  hasBorder?: boolean;
  color?: string;
};

export function PointsCellWrapper({ children, hasBorder = false, color }: PointsCellWrapperProps) {
  return (
    <div
      className={`${CELL_BASE_CLASSES} ${hasBorder ? 'border rounded-full' : ''}`}
      style={hasBorder ? { borderColor: color } : undefined}
    >
      {children}
    </div>
  );
}
