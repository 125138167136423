import { HAS_MORE_TEXT, MAX_DISPLAY_POINTS, SEPARATOR } from '../core/consts';
import { PointsCellWrapper } from './PointsCellWrapper';

type MultiplePointsDisplayProps = {
  points: number[];
  color: string;
};

export function MultiplePointsDisplay({ points, color }: MultiplePointsDisplayProps) {
  const displayPoints = points.slice(0, MAX_DISPLAY_POINTS);
  const hasMore = points.length > MAX_DISPLAY_POINTS;

  return (
    <PointsCellWrapper>
      <p className="text-sm font-medium" style={{ color }}>
        {displayPoints.join(SEPARATOR)}
        {hasMore ? HAS_MORE_TEXT : ''}
      </p>
    </PointsCellWrapper>
  );
}
