import { MultiplePointsDisplay } from './MultiplePointsDisplay';
import { PointsCellWrapper } from './PointsCellWrapper';
import { SinglePointDisplay } from './SinglePointDisplay';

type PointsCellProps = {
  points?: number | null;
  pointsOptions?: number[] | null;
  color: string;
};

export function PointsCell({ points, pointsOptions, color }: PointsCellProps) {
  const hasNoPointsData = !points && !pointsOptions?.length;
  const hasSinglePointValue = !!points && (!pointsOptions || pointsOptions.length === 0);
  const hasMultiplePointOptions = !!pointsOptions?.length;

  if (hasNoPointsData) {
    return <PointsCellWrapper />;
  }

  if (hasSinglePointValue) {
    return <SinglePointDisplay point={points} color={color} />;
  }

  if (hasMultiplePointOptions) {
    return <MultiplePointsDisplay points={pointsOptions} color={color} />;
  }

  return null;
}
