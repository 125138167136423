import { colors } from '../../../styles/variables';
import SearchIcon from '../../icons/SearchIcon';
import DecoratedInput from '../inputs/DecoratedInput';
import { DisplaySelectedItems } from './components/DisplaySelectedItems';

interface AutocompleteListWrapperProps {
  searchValue: string;
  setSearchValue: (newValue: string) => void;
  inputPlaceholder?: string;
  children: React.ReactNode;
  buttons?: React.ReactNode;
  selectedItemsNumber: number;
  filter?: React.ReactNode;
  filterChipSection?: React.ReactNode;
  selectedToRemoveQuestions?: number;
  isAvailableToSelect?: boolean;
}

const AutocompleteListWrapper = ({
  searchValue,
  setSearchValue,
  inputPlaceholder,
  children,
  buttons,
  selectedItemsNumber,
  filter,
  filterChipSection,
  selectedToRemoveQuestions,
  isAvailableToSelect,
}: AutocompleteListWrapperProps) => {
  return (
    <div>
      <DecoratedInput
        inputProps={{
          value: searchValue,
          onChange: e => setSearchValue(e.target.value),
          placeholder: inputPlaceholder,
          className: !!filter ? 'px-14' : '',
        }}
        prefix={filter}
        sufix={
          <div className="absolute right-4 top-4">
            <SearchIcon color={colors.grey500} />
          </div>
        }
      />

      <div className="flex flex-col">
        {filterChipSection}
        <div className="h-[437px] max-h-[437px] border border-grey500 rounded-[4px] overflow-y-scroll mt-3 py-2 custom-scrollbar grow">
          {children}
        </div>
      </div>
      <div className="flex justify-between items-center mt-3">
        {isAvailableToSelect ? (
          <DisplaySelectedItems items={selectedItemsNumber} text={'autocompleteList.markedPositions'} />
        ) : (
          <div className="flex flex-col gap-2">
            <DisplaySelectedItems items={selectedItemsNumber} text={'autocompleteList.selectedPositions'} />
            {selectedToRemoveQuestions ? (
              <DisplaySelectedItems items={selectedToRemoveQuestions} text={'autocompleteList.markedPositions'} />
            ) : null}
          </div>
        )}

        <div>{buttons}</div>
      </div>
    </div>
  );
};

export default AutocompleteListWrapper;
