import { useCallback, useEffect, useMemo, useState } from 'react';

import type { IQuestion } from '../../../../types/ApiTypes';
import { type Coordinates, type GameType, GameTypes } from '../../../../types/GlobalTypes';
import { getGoogleMapAreaCoordinates } from '../../../../utils/map/getGoogleMapAreaCoordinates/getGoogleMapAreaCoordinates';
import { getIndividualGamePossibleQuestions } from '../../../../utils/questions/getIndividualGamePossibleQuestions/getIndividualGamePossibleQuestions';

const useHandleTemplateQuestions = ({
  allQuestions,
  initialSelectedQuestions,
  currentGameType,
}: {
  allQuestions: IQuestion[];
  initialSelectedQuestions: IQuestion[];
  currentGameType: GameType;
}) => {
  const [preSelectedQuestions, setPreSelectedQuestions] = useState<IQuestion[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<IQuestion[]>(initialSelectedQuestions);
  const [lastClickedQuestion, setLastClickedQuestion] = useState<IQuestion | null>(null);

  useEffect(() => {
    const isIndividual = currentGameType === GameTypes.INDIVIDUAL;

    if (isIndividual) {
      setSelectedQuestions(prevSelected => getIndividualGamePossibleQuestions(prevSelected));
    }
  }, [currentGameType]);

  const [mapInstance, setMapInstance] = useState<google.maps.Map | null>(null);

  const updateSelectedQuestion = (updatedQuestion: IQuestion) => {
    setSelectedQuestions(prevQuestions => {
      return prevQuestions.map(prevQuestion => {
        if (prevQuestion.id === updatedQuestion.id) {
          return updatedQuestion;
        } else {
          return prevQuestion;
        }
      });
    });
  };

  const allPossibleQuestions = useMemo(() => {
    if (currentGameType === GameTypes.INDIVIDUAL) {
      return getIndividualGamePossibleQuestions(allQuestions);
    } else {
      return allQuestions;
    }
  }, [allQuestions, currentGameType]);

  const availableQuestions = useMemo(() => {
    const questionsWithoutSelected = allPossibleQuestions.filter(
      question => !selectedQuestions.some(selectedQuestion => selectedQuestion.id === question.id),
    );

    return questionsWithoutSelected.sort((a, b) => {
      return b.id - a.id;
    });
  }, [allPossibleQuestions, selectedQuestions]);

  const togglePreSelectedQuestion = (event: MouseEvent, clickedQuestion: IQuestion) => {
    if (event.metaKey) {
      setPreSelectedQuestions(prevSelected => {
        const isSelected = prevSelected.some(question => question.id === clickedQuestion.id);
        if (isSelected) {
          return prevSelected.filter(question => question.id !== clickedQuestion.id);
        }
        return [...prevSelected, clickedQuestion];
      });
    } else if (event.shiftKey && lastClickedQuestion) {
      const lastIndex = availableQuestions.findIndex(q => q.id === lastClickedQuestion.id);
      const clickedIndex = availableQuestions.findIndex(q => q.id === clickedQuestion.id);

      const [start, end] = lastIndex < clickedIndex ? [lastIndex, clickedIndex] : [clickedIndex, lastIndex];
      const range = availableQuestions.slice(start, end + 1);

      setPreSelectedQuestions(range);
    } else {
      setPreSelectedQuestions([clickedQuestion]);
      setLastClickedQuestion(clickedQuestion);
    }
  };

  const addToPreSelectedQuestions = (question: IQuestion) => {
    setPreSelectedQuestions(prevQuestions => [...prevQuestions, question]);
  };

  const removeFromPreSelectedQuestions = (newQuestion: IQuestion) => {
    setPreSelectedQuestions(prevQuestions => {
      const foundQuestion = prevQuestions.find(question => question.id === newQuestion.id);

      return prevQuestions.filter(question => question.id !== foundQuestion?.id);
    });
  };

  const getCurrentMapCenter = useCallback(() => {
    const currentMapBouds = mapInstance?.getBounds();

    if (!currentMapBouds) {
      return;
    }

    const coordinates = getGoogleMapAreaCoordinates(currentMapBouds);
    const center: Coordinates = {
      lat: (coordinates.ne.lat + coordinates.sw.lat) / 2,
      lng: (coordinates.ne.lng + coordinates.sw.lng) / 2,
    };

    return center;
  }, [mapInstance]);

  const addToSelectedQuestions = (newQuestions: IQuestion[]) => {
    const questionsWithoutCoordinates = newQuestions.filter(question => !question.coordinates);

    if (questionsWithoutCoordinates.length !== 0) {
      const mapCenter = getCurrentMapCenter();

      if (!mapCenter) {
        return;
      }

      questionsWithoutCoordinates.forEach(question => (question.coordinates = mapCenter));
    }

    setSelectedQuestions(prevQuestions => [...prevQuestions, ...newQuestions]);
    setPreSelectedQuestions([]);
  };

  const removeFromSelectedQuestions = (questionsToRemove: IQuestion[]) => {
    setSelectedQuestions(prevQuestions => {
      return prevQuestions.filter(prevQuestion => !questionsToRemove.some(question => question.id === prevQuestion.id));
    });
  };

  const updateQuestionsCoordinates = ({
    newCoordinates,
    questionId,
  }: {
    newCoordinates: Coordinates;
    questionId: number;
  }) => {
    setSelectedQuestions(prevQuestions => {
      const newQuestions = [...prevQuestions];

      const questionToChange = newQuestions.find(question => question.id === questionId);

      if (!questionToChange) {
        return newQuestions;
      }

      questionToChange.coordinates = newCoordinates;

      return newQuestions;
    });
  };

  const totalPointsToScore = useMemo(() => {
    const initial = 0;
    return selectedQuestions.reduce((acc, curr) => acc + (curr?.points ?? 0), initial);
  }, [selectedQuestions]);

  return {
    preSelectedQuestions,
    setPreSelectedQuestions,
    allPossibleQuestions,
    availableQuestions,
    selectedQuestions,
    setSelectedQuestions,
    togglePreSelectedQuestion,
    addToPreSelectedQuestions,
    removeFromPreSelectedQuestions,
    addToSelectedQuestions,
    removeFromSelectedQuestions,
    updateQuestionsCoordinates,
    totalPointsToScore,
    setMapInstance,
    getCurrentMapCenter,
    updateSelectedQuestion,
  };
};

export default useHandleTemplateQuestions;
