import type { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../../../../styles/variables';
import type { IQuestion } from '../../../../../../types/ApiTypes';
import type { Coordinates } from '../../../../../../types/GlobalTypes';
import AutocompleteListWrapper from '../../../../../common/autocompleteList/AutocompleteListWrapper';
import { PrimaryButton } from '../../../../../common/buttons/PrimaryButton';
import ArrowLeft from '../../../../../icons/ArrowLeft';
import FilterChipSection from './components/FilterChipSection';
import FilterDropdownComponent from './components/FilterDropdownComponent';
import NoSelectedQuestionsMessage from './components/NoSelectedQuestionsMessage';
import QuestionList from './components/QuestionList';
import useFilteredQuestions from './hooks/useFilteredQuestions';
import useSelectedQuestions from './hooks/useSelectedQuestions';

interface SelectedQuestionsProps {
  selectedQuestions: IQuestion[];
  setSelectedQuestions: Dispatch<SetStateAction<IQuestion[]>>;
  onRemoveFromGame: (questions: IQuestion[]) => void;
  onUpdateQuestionCoordinates: ({
    newCoordinates,
    questionId,
  }: {
    newCoordinates: Coordinates;
    questionId: number;
  }) => void;
  onUpdateSelectedQuestion: (question: IQuestion) => void;
}

const SelectedQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
  onRemoveFromGame,
  onUpdateQuestionCoordinates,
  onUpdateSelectedQuestion,
}: SelectedQuestionsProps) => {
  const { t } = useTranslation();

  const {
    searchValue,
    setSearchValue,
    questionTypeFilter,
    setQuestionTypeFilter,
    tagFilters,
    cityFilter,
    setCityFilter,
    isArcadeFilter,
    setIsArcade,
    handleChangeTagFilters,
    handleDeleteTagFilter,
    filteredQuestions,
    isSomeFilterActive,
    ownerFilter,
    setOwnerFilter,
    activeOwnerIds,
  } = useFilteredQuestions(selectedQuestions);

  const {
    selectedToRemoveQuestions,
    toggleSelectedToRemoveQuestions,
    handleRemoveFromGame,
    handleMoveUp,
    handleMoveDown,
  } = useSelectedQuestions(selectedQuestions, setSelectedQuestions);

  return (
    <>
      <p className="text-black900 text-sm mb-3">{t('templateForm.questions.selectedQuestions')}</p>

      <AutocompleteListWrapper
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        selectedItemsNumber={selectedQuestions?.length}
        selectedToRemoveQuestions={selectedToRemoveQuestions.length}
        isAvailableToSelect={false}
        buttons={
          <PrimaryButton
            onClick={() => handleRemoveFromGame(onRemoveFromGame)}
            className="w-fit"
            disabled={!(selectedToRemoveQuestions?.length > 0)}
            prefixIcon={<ArrowLeft color={colors.white900} />}
          >
            {t('templateForm.questions.removeFromGameBtn')}
          </PrimaryButton>
        }
        filter={
          <div className="absolute left-0 top-0">
            <FilterDropdownComponent
              isSomeFilterActive={isSomeFilterActive}
              tagFilters={tagFilters}
              questionTypeFilter={questionTypeFilter}
              cityFilter={cityFilter}
              isArcadeFilter={isArcadeFilter}
              handleChangeTagFilters={handleChangeTagFilters}
              setQuestionTypeFilter={setQuestionTypeFilter}
              setCityFilter={setCityFilter}
              setIsArcade={setIsArcade}
              setOwnerFilter={setOwnerFilter}
              activeOwnerIds={activeOwnerIds}
            />
          </div>
        }
        filterChipSection={
          <FilterChipSection
            isSomeFilterActive={isSomeFilterActive}
            questionTypeFilter={questionTypeFilter}
            cityFilter={cityFilter}
            tagFilters={tagFilters}
            isArcadeFilter={isArcadeFilter}
            setQuestionTypeFilter={setQuestionTypeFilter}
            setCityFilter={setCityFilter}
            handleDeleteTagFilter={handleDeleteTagFilter}
            setIsArcade={setIsArcade}
            ownerFilter={ownerFilter}
            setOwnerFilter={setOwnerFilter}
          />
        }
      >
        <>
          {filteredQuestions.length === 0 ? (
            <NoSelectedQuestionsMessage />
          ) : (
            <QuestionList
              filteredQuestions={filteredQuestions}
              selectedToRemoveQuestions={selectedToRemoveQuestions}
              selectedQuestions={selectedQuestions}
              toggleSelectedToRemoveQuestions={toggleSelectedToRemoveQuestions}
              handleMoveUp={handleMoveUp}
              handleMoveDown={handleMoveDown}
              onUpdateQuestionCoordinates={onUpdateQuestionCoordinates}
              onUpdateSelectedQuestion={onUpdateSelectedQuestion}
            />
          )}
        </>
      </AutocompleteListWrapper>
    </>
  );
};

export default SelectedQuestions;
