import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { paths } from '../../../static/routes';

// TODO: to get strict types (not string) we should mark paths object as const
type Path = typeof paths.editTemplate | typeof paths.addTemplate | typeof paths.addQuestion | typeof paths.editQuestion;

const PATHS: Path[] = [paths.editTemplate, paths.addTemplate, paths.addQuestion, paths.editQuestion];

function getPathSegment(path: string) {
  const segment = path.split('/').at(-1);
  return segment || null;
}

function isPathMatch(pathname: string, path: string): boolean {
  const segment = getPathSegment(path);
  return segment ? pathname.includes(segment) : false;
}

export function useIsPathMatch() {
  const { pathname } = useLocation();

  return useMemo(() => {
    return PATHS.some(path => isPathMatch(pathname, path));
  }, [pathname]);
}
