import { useTranslation } from 'react-i18next';

type DisplaySelectedItemsProps = {
  items: number;
  text: string;
};

export function DisplaySelectedItems({ items, text }: DisplaySelectedItemsProps) {
  const { t } = useTranslation();

  return (
    <div>
      {items > 0 && (
        <p className="text-sm font-medium text-black400">
          <span className="font-semibold text-black900">{`(${items}) `}</span>
          {t(text)}
        </p>
      )}
    </div>
  );
}
