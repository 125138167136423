import { GoogleMap, type GoogleMapProps, StreetViewPanorama } from '@react-google-maps/api';
import { type CSSProperties, useRef } from 'react';
import { elementsIds } from '../../static/elementsIds';
import type { Coordinates } from '../../types/GlobalTypes';
import MapTypeSelect from './components/MapTypeSelect/MapTypeSelect';
import MapZoomButtons from './components/MapZoomButton';
import { useFitBounds } from './hooks/useFitBounds';
import { useIsPathMatch } from './hooks/useIsPathMatch';
import { useLoadMap } from './hooks/useLoadMap';
import { useMapCenter } from './hooks/useMapCenter';
import useMapZoom from './hooks/useMapZoom';
import { useStreetViewVisibility } from './hooks/useStreetViewVisibility';
import './map.css';
import { defaultMapContainerStyle, getDefaultMapOptions, streetViewOptions } from './static/mapDefaults';

interface MapProps extends GoogleMapProps {
  children: React.ReactNode;
  center?: Coordinates;
  initialZoom?: number;
  options?: google.maps.MapOptions;
  containerStyle?: CSSProperties;
  hideZoomButtons?: boolean;
  onMapClick?: (e: google.maps.MapMouseEvent) => void;
  bounds?: google.maps.LatLngBounds | null;
  onMapLoad?: (map: google.maps.Map) => void;
}

const Map = ({
  children,
  center,
  initialZoom,
  options,
  containerStyle,
  hideZoomButtons = false,
  onMapClick,
  bounds,
  onMapLoad,
}: MapProps) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const isLoaded = useLoadMap();
  const getMapCenterPoint = useMapCenter(center);
  const { isStreetViewVisible, handleMapVisibleChanged } = useStreetViewVisibility(mapRef);
  const [zoom, { zoomIn, zoomOut, updateZoom }] = useMapZoom({
    initial: initialZoom,
  });
  const canDisplayMapSelect = useIsPathMatch();

  useFitBounds(mapRef, bounds);

  function handleZoomChanged() {
    const newZoom = mapRef.current?.getZoom() ?? zoom;
    updateZoom(newZoom);
  }

  return isLoaded ? (
    <div id={elementsIds.map} className="relative">
      {canDisplayMapSelect && <MapTypeSelect mapRef={mapRef} />}
      <GoogleMap
        onLoad={map => {
          mapRef.current = map;

          if (onMapLoad) {
            onMapLoad(map);
          }
        }}
        mapContainerStyle={{ ...defaultMapContainerStyle, ...containerStyle }}
        center={getMapCenterPoint()}
        zoom={zoom}
        onZoomChanged={handleZoomChanged}
        options={{ ...getDefaultMapOptions(), ...options }}
        onClick={onMapClick}
      >
        {!hideZoomButtons && !isStreetViewVisible && (
          <div className="z-10 absolute bottom-3 right-2 flex flex-col gap-1.5">
            <MapZoomButtons onClickPlus={zoomIn} onClickMinus={zoomOut} />
          </div>
        )}
        {children}
        <StreetViewPanorama options={streetViewOptions} onVisibleChanged={handleMapVisibleChanged} />
      </GoogleMap>
    </div>
  ) : null;
};

export default Map;
