import { PointsCellWrapper } from './PointsCellWrapper';

type SinglePointDisplayProps = {
  point: number;
  color: string;
};

export function SinglePointDisplay({ point, color }: SinglePointDisplayProps) {
  return (
    <PointsCellWrapper hasBorder color={color}>
      <p className="text-sm font-medium" style={{ color }}>
        {point}
      </p>
    </PointsCellWrapper>
  );
}
