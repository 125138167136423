import { type MouseEvent, useState } from 'react';
import type { IQuestion } from '../../../../../../../types/ApiTypes';
import { swapArrayItemsPosition } from '../../../../../../../utils/general/swapArrayItemsPosition/swapArrayItemsPosition';

function useSelectedQuestions(
  selectedQuestions: IQuestion[],
  setSelectedQuestions: React.Dispatch<React.SetStateAction<IQuestion[]>>,
) {
  const [selectedToRemoveQuestions, setSelectedToRemoveQuestions] = useState<IQuestion[]>([]);
  const [lastSelectedQuestion, setLastSelectedQuestion] = useState<IQuestion | null>(null);

  function toggleSelectedToRemoveQuestions(event: MouseEvent, newQuestion: IQuestion) {
    if (event.metaKey) {
      setSelectedToRemoveQuestions(prevSelected => {
        const isSelected = prevSelected.some(question => question.id === newQuestion.id);
        if (isSelected) {
          return prevSelected.filter(question => question.id !== newQuestion.id);
        }
        return [...prevSelected, newQuestion];
      });
    } else if (event.shiftKey && lastSelectedQuestion) {
      const lastIndex = selectedQuestions.findIndex(q => q.id === lastSelectedQuestion.id);
      const newIndex = selectedQuestions.findIndex(q => q.id === newQuestion.id);

      const [start, end] = lastIndex < newIndex ? [lastIndex, newIndex] : [newIndex, lastIndex];
      const range = selectedQuestions.slice(start, end + 1);

      setSelectedToRemoveQuestions(range);
    } else {
      setSelectedToRemoveQuestions([newQuestion]);
      setLastSelectedQuestion(newQuestion);
    }
  }

  const handleRemoveFromGame = (onRemoveFromGame: (questions: IQuestion[]) => void) => {
    onRemoveFromGame(selectedToRemoveQuestions);
    setSelectedToRemoveQuestions([]);
  };

  const moveQuestion = (oldIndex: number, newIndex: number) => {
    setSelectedQuestions(prevQuestions => {
      const newSortedQuestions = [...prevQuestions];
      swapArrayItemsPosition(newSortedQuestions, oldIndex, newIndex);

      return newSortedQuestions;
    });
  };

  const handleMoveUp = ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => {
    e.stopPropagation();
    moveQuestion(currentIndex, currentIndex - 1);
  };

  const handleMoveDown = ({ e, currentIndex }: { e: MouseEvent<HTMLButtonElement>; currentIndex: number }) => {
    e.stopPropagation();
    moveQuestion(currentIndex, currentIndex + 1);
  };

  return {
    selectedToRemoveQuestions,
    toggleSelectedToRemoveQuestions,
    handleRemoveFromGame,
    handleMoveUp,
    handleMoveDown,
  };
}

export default useSelectedQuestions;
